import React from 'react';
import { Media } from '../types'
import { MediaItem } from '../ui-components/MediaItem';

type MediaSectionProps = {
    title: string;
    mediaList: Media[];
}

const MediaSection = (props: MediaSectionProps) => {
    return (
        <div className='media-section-container'>
            <div className='media-section-header'>
                <span className='media-section-title'>{props.title}</span>
            </div>
            <div className='media-list'>
                {props.mediaList.map(media => <MediaItem title={media.title} progress={media.progress} type={media.type} key={media.title}/>)}
            </div>
        </div>
    )
}

export default MediaSection;