import React from 'react'

import { FaBookOpen } from 'react-icons/fa';
import { IoLogoGameControllerB } from 'react-icons/io';
import { BsFillPlayBtnFill } from 'react-icons/bs';

const SIZE_PIXELS = 30
const FILL = '#ffffff'
const MediaIcon = (props: {type: string}) => {
    

    const renderSwitch = (type: string) => {
        switch(type) {
            case 'book':
                return <FaBookOpen size={SIZE_PIXELS} fill={FILL}/>  
            case 'game':
                return <IoLogoGameControllerB size={SIZE_PIXELS} fill={FILL}/>
            default:
                return <BsFillPlayBtnFill size={SIZE_PIXELS} fill={FILL}/>
        }
      }
    return (
        <div className='media-icon'>
            {renderSwitch(props.type)}
        </div>
    )
}

export default MediaIcon