import React from 'react';
import MediaCard from './MediaCard';
import MediaLabel from './MediaLabel';
import { Media } from '../types';


export const MediaItem = (props: Media) => {
    return (
        <div>
            <MediaCard progress={props.progress}/>
            <MediaLabel type={props.type} title={props.title}/>
        </div>
    )
}