import './App.css';
import React, { useState } from 'react';
import MediaSection from './components/MediaSection';



function App() {
  // Define some sample book data


  return (
    <div className="App">
      <InProgressSection />
    </div>
  );
}

export default App;


const InProgressSection = () => {

  const initialBooks = [
    { title: 'Dawnshard', progress: 50, type: 'book' },
    { title: `Winter's Heart`, progress: 30, type: 'book' },
    { title: 'Red Rising', progress: 10, type: 'book' },
  ];
  const [media, setMedia] = useState(initialBooks);


  return (
    <MediaSection title={"In Progress"} mediaList={media}/>
  )

  // return (
  //   <div className="media-list">
  //           {media.map(media => <MediaCard title={media.title} progress={media.progress} type={media.type} key={media.title}/>)}
  //         </div>
  // )
}