import React from 'react';
import './../App.css';

type MediaCardProps = {
    progress: number;
}

const MediaCard = (props: MediaCardProps) => {

    return (
        <div className="card-container">
            <span className='progress-span'>{props.progress}%</span>
        </div>
    )
}
export default MediaCard;