import React from 'react';
import MediaIcon from './MediaIcon'


type MediaLabelProps = {
    title: string;
    type: string
}

const MediaLabel = (props:MediaLabelProps) => {
    return (
        <div className='media-label'>
            <MediaIcon type={props.type} />
            <div className="card-details">
                <span className="media-card-title">{props.title}</span>
                <span className="media-card-subtitle"/>
            </div>
        </div>
       
    )
}

export default MediaLabel